body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (min-width: 990px) {
  #authen-mobile {
    display: none;
  }
}

@media only screen and (max-width: 430px) {
  #authen-mobile {
    right: 50px;
  }
}

@media only screen and (min-width: 431px) {
  #authen-mobile {
    right: 160px;
  }
}

@media only screen and (max-width: 331px) {
  #authen-mobile {
    display: none;
  }
}

@media only screen and (min-width: 1220px) {
  .header-custom {
    max-width: 1340px;
  }
}

@media only screen and (min-width: 992px) {
  .header-custom {
    max-width: 1340px;
  }
}

.show-list {
  display: block !important;
}

@media only screen and (max-width: 767px) {
  .my-container {
    margin-top: 96px;
  }
}
